import { inject, Injectable } from '@angular/core';
import { ExperimentService } from '../utils/experiment.service';

export enum SchedulingVariants {
  SCHEDULING_EMBED_VARIANT = 'scheduling_embed_variant',
  SCHEDULING_WIDGET_VARIANT = 'scheduling_widget_variant',
}

@Injectable({
  providedIn: 'root',
})
export class SchedulingExperimentService {
  private experimentService = inject(ExperimentService);
  public readonly SCHEDULING_EXPERIMENT_KEY = 'scheduling-experiment-key';

  private readonly VARIANTS = [
    SchedulingVariants.SCHEDULING_EMBED_VARIANT,
    SchedulingVariants.SCHEDULING_WIDGET_VARIANT,
  ];

  async startSchedulingExperiment(): Promise<SchedulingVariants> {
    try {
      const variant = await this.experimentService.startExperiment(this.SCHEDULING_EXPERIMENT_KEY, this.VARIANTS);

      return variant as SchedulingVariants;
    } catch (error) {
      console.error('Error starting scheduling experiment:', error);
      return SchedulingVariants.SCHEDULING_EMBED_VARIANT;
    }
  }

  getVariant(): SchedulingVariants {
    return this.experimentService.getVariant(this.SCHEDULING_EXPERIMENT_KEY, this.VARIANTS) as SchedulingVariants;
  }
}
