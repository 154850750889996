import { inject, Injectable } from '@angular/core';
import {
  ONBOARDING_STEPS_DEFINITION_CHAPTER,
  ONBOARDING_STEPS_DEFINITION_DIRECT,
  ONBOARDING_STEPS_DEFINITION_MARSHFIELD,
  ONBOARDING_STEPS_DEFINITION_MODA,
  ONBOARDING_STEPS_DEFINITION_NO_HARD_ELIGIBILITY_STEPS_PAGE,
  ONBOARDING_STEPS_DEFINITION_OPTUM,
} from '../pages/onboarding-v2/onboarding-steps-definition';
import { ExperimentService } from '../utils/experiment.service';
import { StorageService } from '../utils/storage.service';

interface OnboardingVariant {
  name: string;
  route: string[];
}

const ONBOARDING_EXPERIMENT_KEY = 'web-onboarding';

export const WEB_ONBOARDING_OPTUM_VARIANT: OnboardingVariant = {
  name: 'web_onboarding_optum_variant',
  route: ONBOARDING_STEPS_DEFINITION_OPTUM,
};

export const WEB_ONBOARDING_OPTUM_MARSHFIELD: OnboardingVariant = {
  name: 'web_onboarding_marshfield_variant',
  route: ONBOARDING_STEPS_DEFINITION_MARSHFIELD,
};

export const WEB_ONBOARDING_OPTUM_CHAPTER: OnboardingVariant = {
  name: 'web_onboarding_chapter_variant',
  route: ONBOARDING_STEPS_DEFINITION_CHAPTER,
};

export const WEB_ONBOARDING_OPTUM_MODA: OnboardingVariant = {
  name: 'web_onboarding_moda_variant',
  route: ONBOARDING_STEPS_DEFINITION_MODA,
};

const WEB_ONBOARDING_DIRECT_VARIANT: OnboardingVariant = {
  name: 'web_onboarding_direct',
  route: ONBOARDING_STEPS_DEFINITION_DIRECT,
};

const WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT: OnboardingVariant = {
  name: 'web_onboarding_no_eligibility_steps_page',
  route: ONBOARDING_STEPS_DEFINITION_NO_HARD_ELIGIBILITY_STEPS_PAGE,
};

const WEB_ONBOARDING_REMOVED_CHRONIC_CONDITION_QUESTIONS_VARIANT: OnboardingVariant = {
  name: 'web_onboarding_removed_chronic_condition_questions',
  route: ONBOARDING_STEPS_DEFINITION_NO_HARD_ELIGIBILITY_STEPS_PAGE,
};

@Injectable({
  providedIn: 'root',
})
export class OnboardingExperimentService {
  storage = inject(StorageService);
  experimentService = inject(ExperimentService);

  async startOnboardingExperiment(forceVariants?: string[]): Promise<OnboardingVariant> {
    try {
      const variant = await this.experimentService.startExperiment(
        ONBOARDING_EXPERIMENT_KEY,
        [
          WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT.name,
          WEB_ONBOARDING_REMOVED_CHRONIC_CONDITION_QUESTIONS_VARIANT.name,
        ],
        forceVariants
      );

      return this.selectOnboardingVariant(variant);
    } catch (error) {
      console.error('Error starting onboarding experiment:', error);
      return WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT;
    }
  }

  private selectOnboardingVariant(variant: string): OnboardingVariant {
    const variantMap: Record<string, OnboardingVariant> = {
      [WEB_ONBOARDING_OPTUM_VARIANT.name]: WEB_ONBOARDING_OPTUM_VARIANT,
      [WEB_ONBOARDING_OPTUM_MARSHFIELD.name]: WEB_ONBOARDING_OPTUM_MARSHFIELD,
      [WEB_ONBOARDING_OPTUM_CHAPTER.name]: WEB_ONBOARDING_OPTUM_CHAPTER,
      [WEB_ONBOARDING_OPTUM_MODA.name]: WEB_ONBOARDING_OPTUM_MODA,
      [WEB_ONBOARDING_DIRECT_VARIANT.name]: WEB_ONBOARDING_DIRECT_VARIANT,
      [WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT.name]:
        WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT,
      [WEB_ONBOARDING_REMOVED_CHRONIC_CONDITION_QUESTIONS_VARIANT.name]:
        WEB_ONBOARDING_REMOVED_CHRONIC_CONDITION_QUESTIONS_VARIANT,
    };

    const selectedVariant = variantMap[variant] || WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT;
    ONBOARDING_SELECTED_VARIANT = selectedVariant;

    return selectedVariant;
  }

  skipChronicConditionQuestions() {
    return ONBOARDING_SELECTED_VARIANT.name === WEB_ONBOARDING_REMOVED_CHRONIC_CONDITION_QUESTIONS_VARIANT.name;
  }
}

export let ONBOARDING_SELECTED_VARIANT: OnboardingVariant;
