import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderTheme } from './components/header/header.component.constants';
import { ActivationFlowGuard } from './guards/activation-flow.guard';
import { AuthGuard } from './guards/auth.guard';
import { LandingPageGuard } from './guards/landing-page.guard';
import { prepareOnboardingGuard } from './guards/onboarding.guard';
import { SchedulingGuard } from './guards/scheduling.guard';
import { journalists } from './pages/home/press/press-data.component';
import { FEATURE_FLAG } from './utils/feature-flag.const';
import { SchedulingStandaloneGuard } from './guards/scheduling-standalone.guard';
import { InvalidSchedulingLinkPageComponent } from './pages/scheduling/v2/invalid-link/invalid-scheduling-link-page.component';

const routes: Routes = [
  {
    path: 'community',
    redirectTo: 'community/',
    pathMatch: 'full',
    title: 'Mighty Health - Community',
    data: {
      routeName: 'Community',
      metaTags: {
        custom: [
          {
            property: 'robots',
            content: 'index, follow',
          },
        ],
      },
    },
  },
  {
    path: 'get-app',
    loadChildren: () => import('./pages/get-app/get-app.component').then(m => m.ROUTES),
    title: 'Mighty Health - Get App',
  },
  {
    path: 'community/:groupId',
    loadChildren: () => import('./pages/community-group/community-group.component').then(m => m.ROUTES),
    title: 'Mighty Health - Community - Group',
    data: {
      routeName: 'Community',
      metaTags: {
        title: 'Mighty Health Community',
        description:
          "Join a supportive community of adults over 50 who are working to live the healthiest years of their lives! Make new friends, share recipes, and celebrate your wins. Choose a group based on topics you're most interested in and start posting!",
        custom: [
          {
            property: 'robots',
            content: 'index, follow',
          },
        ],
      },
    },
  },
  {
    path: 'community/:groupId/:postId',
    loadChildren: () => import('./pages/community-post/community-post.component').then(m => m.ROUTES),
    title: 'Mighty Health - Community - Post',
    data: {
      routeName: 'Community',
    },
  },
  // {
  //   path: 'lesson/:taskId',
  //   loadChildren: () => import('./pages/task/task.component').then(m => m.ROUTES),
  //   title: 'Mighty Health - Lesson',
  // },
  {
    path: 'lesson/:taskId/:stepIndex',
    loadChildren: () => import('./pages/task/task.component').then(m => m.ROUTES),
    title: 'Mighty Health - Lesson',
  },
  {
    path: 'member-lesson/:memberTaskId',
    loadChildren: () => import('./pages/member-task/member-task.component').then(m => m.ROUTES),
    canActivate: [AuthGuard],
    title: 'Mighty Health - Lesson',
  },
  {
    path: 'member-lesson/:memberTaskId/:stepIndex',
    loadChildren: () => import('./pages/member-task/member-task.component').then(m => m.ROUTES),
    canActivate: [AuthGuard],
    title: 'Mighty Health - Lesson',
  },
  {
    path: 'workout',
    loadChildren: () => import('./pages/workouts/workouts.component').then(m => m.ROUTES),
    canActivate: [AuthGuard],
    title: 'Mighty Health - Workouts',
  },
  {
    path: 'food',
    loadChildren: () => import('./pages/food/food.component').then(m => m.ROUTES),
    canActivate: [AuthGuard],
    title: 'Mighty Health - Foodlog',
  },
  {
    path: '',
    loadChildren: () => import('./pages/home/home-routing.module').then(m => m.ROUTES),
    title: 'Mighty Health - Home',
    data: {
      transparentHeader: true,
      header: HeaderTheme.TRANSPARENT_LIGHT,
      redirectOnLogin: ['/home'],
      metaTags: {
        custom: [
          {
            property: 'robots',
            content: 'index, follow',
          },
        ],
      },
    },
    canActivate: [LandingPageGuard],
  },
  {
    path: 'enterprise',
    loadChildren: () => import('./pages/home/enterprise/enterprise.component').then(m => m.ROUTES),
    title: 'Mighty Health - For Health Plans',
    data: {
      routeName: 'For Health Plans',
      transparentHeader: true,
      header: HeaderTheme.TRANSPARENT_LIGHT,
      redirectOnLogin: ['/home'],
      metaTags: {
        custom: [
          {
            property: 'robots',
            content: 'index, follow',
          },
        ],
      },
    },
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/member/member.component').then(m => m.ROUTES),
    title: 'Mighty Health - Member Home',
    canActivate: [AuthGuard],
    data: {
      featureFlagRoute: FEATURE_FLAG.NEW_WEBHOME,
      metaTags: {
        custom: [
          {
            property: 'robots',
            content: 'index, follow',
          },
        ],
      },
    },
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/events.component').then(m => m.ROUTES),
    title: 'Mighty Health - Events',
    data: {
      routeName: 'Events',
      metaTags: {
        title: 'Mighty Events',
        description: 'Powerful health events for 50+',
        custom: [
          {
            property: 'robots',
            content: 'index, follow',
          },
        ],
      },
    },
  },
  {
    path: 'events/:eventId',
    loadChildren: () => import('./pages/event/event.component').then(m => m.ROUTES),
    title: 'Mighty Health - Event',
    data: {
      routeName: 'Events',
      metaTags: {
        custom: [
          {
            property: 'robots',
            content: 'index, follow',
          },
        ],
      },
    },
  },
  {
    path: 'forma',
    loadChildren: () => import('./pages/forma/forma-landing-page.component').then(m => m.ROUTES),
    title: 'Mighty Health - Forma',
  },
  {
    path: 'optum',
    loadChildren: () => import('./pages/optum/optum.routes').then(m => m.ROUTES),
    title: 'Mighty Health - Optum',
  },
  {
    path: 'chapter',
    loadChildren: () => import('./pages/chapter-landing-page/chapter.routes').then(m => m.ROUTES),
    title: 'Mighty Health - Chapter',
  },
  {
    path: 'marshfield',
    loadChildren: () => import('./pages/marshfield/marshfield.routes').then(m => m.ROUTES),
    title: 'Mighty Health - Marshfield',
  },
  {
    path: 'scan',
    loadChildren: () => import('./pages/scan/scan.routes').then(m => m.ROUTES),
    title: 'Mighty Health - SCAN',
  },
  {
    path: 'SCAN',
    loadChildren: () => import('./pages/scan/scan.routes').then(m => m.ROUTES),
    title: 'Mighty Health - SCAN',
  },
  {
    path: 'exercises',
    redirectTo: '/exercises/list/programs',
    pathMatch: 'full',
  },
  {
    path: 'exercises/list',
    loadChildren: () => import('./pages/exercises/exercises-list/exercises-list.component').then(m => m.ROUTES),
    title: 'Mighty Health - Exercises',
    data: {
      routeName: 'Exercises',
      metaTags: {
        custom: [
          {
            property: 'robots',
            content: 'index, follow',
          },
        ],
      },
    },
  },
  {
    path: 'exercises/:trackId',
    loadChildren: () => import('./pages/track/track.component').then(m => m.ROUTES),
    title: 'Mighty Health - Program',
    data: {
      routeName: 'Exercises',
    },
  },
  {
    path: 'exercises/:trackId/exercise/:exerciseId',
    loadChildren: () => import('./pages/exercise/exercise.component').then(m => m.ROUTES),
    title: 'Mighty Health - Class',
    data: {
      routeName: 'Exercises',
    },
  },
  {
    path: 'exercise/:exerciseId',
    loadChildren: () => import('./pages/exercise/exercise.component').then(m => m.ROUTES),
    title: 'Mighty Health - Class',
    data: {
      routeName: 'Exercises',
    },
  },
  {
    path: 'lesson/:exerciseId',
    loadChildren: () => import('./pages/lesson/lesson.component').then(m => m.ROUTES),
    title: 'Mighty Health - Lesson',
    data: {
      routeName: 'Lesson',
    },
  },
  {
    path: 'clinic-welcome-video',
    loadChildren: () => import('./pages/booking-wall/welcome-video/welcome-video-page.component').then(m => m.ROUTES),
    title: 'Mighty Health - Welcome to Mighty Health',
    data: {
      routeName: 'Welcome to Mighty Health',
    },
  },
  {
    path: 'about-us',
    loadChildren: () => import('./pages/about-us/about-us.component').then(m => m.ROUTES),
    title: 'Mighty Health - About Us',
    data: {
      routeName: 'About Us',
      header: HeaderTheme.TRANSPARENT_DARK,
      redirectOnLogin: ['/home'],
      metaTags: {
        custom: [
          {
            property: 'robots',
            content: 'index, follow',
          },
        ],
      },
    },
  },
  {
    path: 'get-started',
    redirectTo: 'onboarding/welcome',
  },
  {
    path: 'activation-flow',
    loadChildren: () => import('./pages/activation-flow/activation-flow.module').then(m => m.ROUTES),
    title: 'Mighty Health - Activation Flow',
    canActivate: [ActivationFlowGuard],
  },
  {
    path: 'paywall',
    loadChildren: () => import('./pages/paywall/paywall.component').then(m => m.ROUTES),
    title: 'Mighty Health - Payment',
  },
  {
    path: 'pre-scheduling',
    canActivate: [SchedulingGuard],
    loadChildren: () => import('./pages/pre-scheduling/pre-scheduling.component').then(m => m.ROUTES),
    title: 'Mighty Health - Scheduling',
  },
  {
    path: 'scheduling',
    loadChildren: () => import('./pages/scheduling/scheduling.component').then(m => m.ROUTES),
    canActivate: [SchedulingGuard],
    title: 'Mighty Health - Scheduling',
  },
  {
    path: 'scheduling-v2',
    loadChildren: () => import('./pages/scheduling/v2/scheduling-routing.module').then(m => m.SchedulingRoutingModule),
    canActivate: [SchedulingGuard],
    title: 'Mighty Health - Scheduling',
  },
  {
    path: 'book-appointment',
    loadChildren: () => import('./pages/scheduling/v2/scheduling-routing.module').then(m => m.SchedulingRoutingModule),
    canActivate: [SchedulingStandaloneGuard],
    title: 'Mighty Health - Scheduling',
  },
  {
    path: 'invalid-scheduling-link',
    component: InvalidSchedulingLinkPageComponent,
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./pages/onboarding-v2/onboarding-routing.module').then(m => m.OnboardingRoutingModule),
    title: 'Mighty Health - Onboarding V2',
    canActivate: [prepareOnboardingGuard],
  },
  ...journalists.map(item => ({
    path: item.path,
    loadChildren: () => import('./pages/home/press/press.component').then(m => m.ROUTES),
  })),
  {
    path: 'booking-wall',
    loadChildren: () => import('./pages/booking-wall/booking-wall.routes').then(m => m.ROUTES),
    canActivate: [AuthGuard],
  },
  {
    path: 'moda-oebb',
    loadChildren: () => import('./pages/moda/oebb/moda-oebb-landing-page.component').then(m => m.ROUTES),
    title: '#1 rated all-in-one weight management, mobility, and pain program',
  },
  {
    path: 'moda-pebb',
    loadChildren: () => import('./pages/moda/pebb/moda-pebb-landing-page.component').then(m => m.ROUTES),
    title: '#1 rated all-in-one weight management, mobility, and pain program',
  },
  {
    path: 'providence-pebb',
    loadChildren: () =>
      import('./pages/moda/providence-pebb/providence-pebb-landing-page.component').then(m => m.ROUTES),
    title: '#1 rated all-in-one weight management, mobility, and pain program',
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () => import('./pages/not-found/not-found.component').then(m => m.ROUTES),
    title: 'Mighty Health - Not Found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
