import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { MembersService } from '../apis/members.service';
import { Member } from '@mightyhealth/library';

enum SchedulingState {
  AUTH = 'AUTH',
  MISSING_PROVIDER_TYPE = 'MISSING_PROVIDER_TYPE',
  MISSING_CUSTOMER = 'MISSING_CUSTOMER',
  MISSING_STATE = 'MISSING_STATE',
  MISSING_MEMBER = 'MISSING_MEMBER',
  OK = 'OK',
}

@Injectable({
  providedIn: 'root',
})
class CanActivateScheduling {
  private router: Router = inject(Router);
  private memberService = inject(MembersService);
  private member?: Member;

  async canActivate(route: ActivatedRouteSnapshot) {
    this.member = await this.memberService.getCurrentMember();
    /**
     * Check for form and query params
     */
    return this.checkUnauthRequiredParams(route);
  }

  private checkUnauthRequiredParams(route: ActivatedRouteSnapshot) {
    const state = this.getSchedulingState(route);

    switch (state) {
      case SchedulingState.MISSING_PROVIDER_TYPE:
      case SchedulingState.MISSING_MEMBER:
      case SchedulingState.MISSING_CUSTOMER:
      case SchedulingState.MISSING_STATE:
        this.router.navigate(['invalid-scheduling-link']); // send to invalid link page
        return false;
      case SchedulingState.OK:
        return true;
      default:
        return true;
    }
  }

  private getSchedulingState(route: ActivatedRouteSnapshot) {
    let state: SchedulingState;

    state = this.getProviderType(route);
    if (state !== SchedulingState.OK) {
      return state;
    }

    state = this.getMemberInformation(route);
    if (state !== SchedulingState.OK) {
      return state;
    }

    state = this.getCustomer(route);
    if (state !== SchedulingState.OK) {
      return state;
    }

    state = this.getState(route);
    if (state !== SchedulingState.OK) {
      return state;
    }

    return SchedulingState.OK;
  }

  private getProviderType(route: ActivatedRouteSnapshot) {
    const providerType = route.queryParamMap.get('providerType');

    if (!providerType) {
      return SchedulingState.MISSING_PROVIDER_TYPE;
    }

    return SchedulingState.OK;
  }

  private getMemberInformation(route: ActivatedRouteSnapshot) {
    if (this.member) {
      return SchedulingState.OK;
    }

    const memberId = route.queryParamMap.get('memberId');
    if (!memberId) {
      return SchedulingState.MISSING_MEMBER;
    }
    return SchedulingState.OK;
  }

  private getCustomer(route: ActivatedRouteSnapshot) {
    const customerId = route.queryParamMap.get('customerId');
    if (!customerId) {
      return SchedulingState.MISSING_CUSTOMER;
    }

    return SchedulingState.OK;
  }

  private getState(route: ActivatedRouteSnapshot) {
    const state = route.queryParamMap.get('state');
    if (!state) {
      return SchedulingState.MISSING_STATE;
    }
    return SchedulingState.OK;
  }
}

export const SchedulingStandaloneGuard: CanActivateFn = (route: ActivatedRouteSnapshot) =>
  inject(CanActivateScheduling).canActivate(route);
